import React from 'react';
import Translate from 'counterpart';
import CMPGallery from './../cmp-gallery';

import "./../../stylesheets/components/business/bsn-gallery.sass"

class BSNGallery extends React.Component {

	render() {

		const filters = [
			{
				code: 'all',
				title: Translate('pages.services.all')
			},
			{
				code: 'lamination',
				title: Translate('pages.services.lamination')
			},
			{
				code: 'waterproofing',
				title: Translate('pages.services.waterproofing')
			},
			{
				code: 'thermalInsulation',
				title: Translate('pages.services.thermalInsulation')
			},
			{
				code: 'soundproofing',
				title: Translate('pages.services.soundproofing')
			},
			{
				code: 'fireproofing',
				title: Translate('pages.services.fireproofing')
			},
			{
				code: 'thermalInsulationCovers',
				title: Translate('pages.services.thermalInsulationCovers')
			}
		];

		const images = [
			{
				src: '/images/gallery/large/lamination/1.jpg',
				thumb: '/images/gallery/thumbs/lamination/1.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/2.jpg',
				thumb: '/images/gallery/thumbs/lamination/2.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/3.jpg',
				thumb: '/images/gallery/thumbs/lamination/3.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/4.jpg',
				thumb: '/images/gallery/thumbs/lamination/4.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/5.jpg',
				thumb: '/images/gallery/thumbs/lamination/5.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/6.jpg',
				thumb: '/images/gallery/thumbs/lamination/6.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/7.jpg',
				thumb: '/images/gallery/thumbs/lamination/7.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/8.jpg',
				thumb: '/images/gallery/thumbs/lamination/8.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/9.jpg',
				thumb: '/images/gallery/thumbs/lamination/9.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/10.jpg',
				thumb: '/images/gallery/thumbs/lamination/10.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/11.jpg',
				thumb: '/images/gallery/thumbs/lamination/11.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/12.jpg',
				thumb: '/images/gallery/thumbs/lamination/12.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/13.jpg',
				thumb: '/images/gallery/thumbs/lamination/13.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/14.jpg',
				thumb: '/images/gallery/thumbs/lamination/14.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/15.jpg',
				thumb: '/images/gallery/thumbs/lamination/15.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/16.jpg',
				thumb: '/images/gallery/thumbs/lamination/16.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/17.jpg',
				thumb: '/images/gallery/thumbs/lamination/17.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/18.jpg',
				thumb: '/images/gallery/thumbs/lamination/18.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/19.jpg',
				thumb: '/images/gallery/thumbs/lamination/19.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/20.jpg',
				thumb: '/images/gallery/thumbs/lamination/20.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/21.jpg',
				thumb: '/images/gallery/thumbs/lamination/21.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/lamination/22.jpg',
				thumb: '/images/gallery/thumbs/lamination/22.jpg',
				filters: ['lamination']
			},
			{
				src: '/images/gallery/large/waterproofing/1.jpg',
				thumb: '/images/gallery/thumbs/waterproofing/1.jpg',
				filters: ['waterproofing']
			},
			{
				src: '/images/gallery/large/waterproofing/2.jpg',
				thumb: '/images/gallery/thumbs/waterproofing/2.jpg',
				filters: ['waterproofing']
			},
			{
				src: '/images/gallery/large/waterproofing/3.jpg',
				thumb: '/images/gallery/thumbs/waterproofing/3.jpg',
				filters: ['waterproofing']
			},
			{
				src: '/images/gallery/large/waterproofing/4.jpg',
				thumb: '/images/gallery/thumbs/waterproofing/4.jpg',
				filters: ['waterproofing']
			},
			{
				src: '/images/gallery/large/waterproofing/5.jpg',
				thumb: '/images/gallery/thumbs/waterproofing/5.jpg',
				filters: ['waterproofing']
			},
			{
				src: '/images/gallery/large/waterproofing/6.jpg',
				thumb: '/images/gallery/thumbs/waterproofing/6.jpg',
				filters: ['waterproofing']
			},
			{
				src: '/images/gallery/large/waterproofing/7.jpg',
				thumb: '/images/gallery/thumbs/waterproofing/7.jpg',
				filters: ['waterproofing']
			},
			{
				src: '/images/gallery/large/waterproofing/8.jpg',
				thumb: '/images/gallery/thumbs/waterproofing/8.jpg',
				filters: ['waterproofing']
			},
			{
				src: '/images/gallery/large/waterproofing/9.jpg',
				thumb: '/images/gallery/thumbs/waterproofing/9.jpg',
				filters: ['waterproofing']
			},
			{
				src: '/images/gallery/large/waterproofing/10.jpg',
				thumb: '/images/gallery/thumbs/waterproofing/10.jpg',
				filters: ['waterproofing']
			},
			{
				src: '/images/gallery/large/waterproofing/11.jpg',
				thumb: '/images/gallery/thumbs/waterproofing/11.jpg',
				filters: ['waterproofing']
			},
			{
				src: '/images/gallery/large/waterproofing/12.jpg',
				thumb: '/images/gallery/thumbs/waterproofing/12.jpg',
				filters: ['waterproofing']
			},
			{
				src: '/images/gallery/large/thermal-insulation/1.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation/1.jpg',
				filters: ['thermalInsulation']
			},
			{
				src: '/images/gallery/large/thermal-insulation/2.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation/2.jpg',
				filters: ['thermalInsulation']
			},
			{
				src: '/images/gallery/large/thermal-insulation/3.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation/3.jpg',
				filters: ['thermalInsulation']
			},
			{
				src: '/images/gallery/large/thermal-insulation/4.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation/4.jpg',
				filters: ['thermalInsulation']
			},
			{
				src: '/images/gallery/large/thermal-insulation/5.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation/5.jpg',
				filters: ['thermalInsulation']
			},
			{
				src: '/images/gallery/large/thermal-insulation/6.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation/6.jpg',
				filters: ['thermalInsulation']
			},
			{
				src: '/images/gallery/large/thermal-insulation/7.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation/7.jpg',
				filters: ['thermalInsulation']
			},
			{
				src: '/images/gallery/large/thermal-insulation/8.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation/8.jpg',
				filters: ['thermalInsulation']
			},
			{
				src: '/images/gallery/large/thermal-insulation/9.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation/9.jpg',
				filters: ['thermalInsulation']
			},
			{
				src: '/images/gallery/large/thermal-insulation/10.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation/10.jpg',
				filters: ['thermalInsulation']
			},
			{
				src: '/images/gallery/large/thermal-insulation/11.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation/11.jpg',
				filters: ['thermalInsulation']
			},
			{
				src: '/images/gallery/large/thermal-insulation/12.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation/12.jpg',
				filters: ['thermalInsulation']
			},
			{
				src: '/images/gallery/large/thermal-insulation/13.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation/13.jpg',
				filters: ['thermalInsulation']
			},
			{
				src: '/images/gallery/large/thermal-insulation/14.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation/14.jpg',
				filters: ['thermalInsulation']
			},
			{
				src: '/images/gallery/large/thermal-insulation/15.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation/15.jpg',
				filters: ['thermalInsulation']
			},
			{
				src: '/images/gallery/large/thermal-insulation/16.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation/16.jpg',
				filters: ['thermalInsulation']
			},
			{
				src: '/images/gallery/large/thermal-insulation/17.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation/17.jpg',
				filters: ['thermalInsulation']
			},
			{
				src: '/images/gallery/large/thermal-insulation/18.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation/18.jpg',
				filters: ['thermalInsulation']
			},
			{
				src: '/images/gallery/large/thermal-insulation/19.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation/19.jpg',
				filters: ['thermalInsulation']
			},
			{
				src: '/images/gallery/large/soundproofing/1.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/1.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/2.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/2.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/3.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/3.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/4.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/4.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/5.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/5.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/6.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/6.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/7.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/7.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/8.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/8.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/9.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/9.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/10.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/10.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/11.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/11.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/12.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/12.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/13.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/13.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/14.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/14.jpg',
				filters: ['soundproofing-insulation']
			},
			{
				src: '/images/gallery/large/soundproofing/15.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/15.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/16.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/16.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/17.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/17.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/18.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/18.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/19.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/19.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/20.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/20.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/21.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/21.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/22.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/22.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/23.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/23.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/24.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/24.jpg',
				filters: ['soundproofing-insulation']
			},
			{
				src: '/images/gallery/large/soundproofing/25.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/25.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/26.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/26.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/27.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/27.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/soundproofing/28.jpg',
				thumb: '/images/gallery/thumbs/soundproofing/28.jpg',
				filters: ['soundproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/1.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/1.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/2.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/2.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/3.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/3.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/4.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/4.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/5.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/5.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/6.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/6.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/7.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/7.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/8.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/8.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/9.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/9.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/10.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/10.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/11.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/11.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/12.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/12.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/13.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/13.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/14.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/14.jpg',
				filters: ['fireproofing-insulation']
			},
			{
				src: '/images/gallery/large/fireproofing/15.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/15.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/16.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/16.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/17.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/17.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/18.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/18.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/19.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/19.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/20.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/20.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/21.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/21.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/22.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/22.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/23.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/23.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/24.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/24.jpg',
				filters: ['fireproofing-insulation']
			},
			{
				src: '/images/gallery/large/fireproofing/25.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/25.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/26.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/26.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/27.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/27.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/28.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/28.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/29.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/29.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/30.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/30.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/31.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/31.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/32.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/32.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/33.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/33.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/34.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/34.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/fireproofing/35.jpg',
				thumb: '/images/gallery/thumbs/fireproofing/35.jpg',
				filters: ['fireproofing']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/1.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/1.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/2.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/2.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/3.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/3.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/4.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/4.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/5.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/5.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/6.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/6.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/7.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/7.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/8.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/8.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/9.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/9.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/10.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/10.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/11.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/11.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/12.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/12.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/13.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/13.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/14.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/14.jpg',
				filters: ['thermal-insulation-covers-insulation']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/15.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/15.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/16.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/16.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/17.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/17.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/18.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/18.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/19.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/19.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/20.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/20.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/21.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/21.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/22.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/22.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/23.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/23.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/24.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/24.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/25.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/25.jpg',
				filters: ['thermalInsulationCovers']
			},
			{
				src: '/images/gallery/large/thermal-insulation-covers/26.jpg',
				thumb: '/images/gallery/thumbs/thermal-insulation-covers/26.jpg',
				filters: ['thermalInsulationCovers']
			},
		];

		return (
			<section className="bsn-gallery">

				<CMPGallery filters={filters} images={images} />

			</section>
		);
	}
}

export default BSNGallery;
