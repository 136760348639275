import React from 'react';
import { ChangeLanguage } from './../../pages/index';
import Translate from 'react-translate-component';
import { CMPMenu, CMPMenuItem } from './../cmp-menu';
import CMPLogo from './../cmp-logo';
import Responsive from './../../helpers/responsive';
import "./../../stylesheets/components/business/bsn-navigation-panel.sass"

class BSNNavigationPanel extends React.Component {

	constructor(props) {
		super(props);
		this.state = { language: "el" };
		this.changeLanguage = this.changeLanguage.bind(this);
	}

	changeLanguage(lang) {
		ChangeLanguage(lang);
	}

	render() {

		const device = new Responsive();

		if (device.IsMobile()) {
			return (
				<div className="bsn-navigation-panel">
					<div className="bsn-navigation-panel__wrapper">

						<CMPMenu mobile={true} show={false}>
							<CMPMenuItem path='/#' title={<Translate content="menu.home" />} active={this.props.active === "home" ? true : false} />
							<CMPMenuItem path='/services' title={<Translate content="menu.services" />} active={this.props.active === "services" ? true : false} />
							{/* <CMPMenuItem path='/clients' title={<Translate content="menu.clients" />} active={this.props.active === "clients" ? true : false} /> */}
							<CMPMenuItem path='/gallery' title={<Translate content="menu.gallery" />} active={this.props.active === "gallery" ? true : false} />
							<CMPMenuItem path='/about' title={<Translate content="menu.about" />} active={this.props.active === "about" ? true : false} />
							<CMPMenuItem path='/contact' title={<Translate content="menu.contact" />} active={this.props.active === "contact" ? true : false} />
						</CMPMenu>

						<CMPLogo imagePath="images/logo.png" title="Kouzoglou Insulations" shortTitle="kouzoglou Insulations" />

						<CMPMenu show={true}>
							<CMPMenuItem action={() => this.changeLanguage("el")} title={<Translate content="language.el.abbr" />} active={this.props.language === "el" ? true : false} />
							<CMPMenuItem action={() => this.changeLanguage("en")} title={<Translate content="language.en.abbr" />} active={this.props.language === "en" ? true : false} />
						</CMPMenu>

					</div>
				</div>
			);
		}

		return (
			<div className="bsn-navigation-panel">
				<div className="bsn-navigation-panel__wrapper">
					<CMPLogo imagePath="images/logo.png" title="Kouzoglou Insulations" shortTitle="kouzoglou Insulations" />
					<div className="bsn-navigation-panel__menus">
						<CMPMenu show={true}>
							<CMPMenuItem path='/#' title={<Translate content="menu.home" />} active={this.props.active === "home" ? true : false} />
							<CMPMenuItem path='/services' title={<Translate content="menu.services" />} active={this.props.active === "services" ? true : false} />
							{/* <CMPMenuItem path='/clients' title={<Translate content="menu.clients" />} active={this.props.active === "clients" ? true : false} /> */}
							<CMPMenuItem path='/gallery' title={<Translate content="menu.gallery" />} active={this.props.active === "gallery" ? true : false} />
							<CMPMenuItem path='/about' title={<Translate content="menu.about" />} active={this.props.active === "about" ? true : false} />
							<CMPMenuItem path='/contact' title={<Translate content="menu.contact" />} active={this.props.active === "contact" ? true : false} />
						</CMPMenu>

						<CMPMenu show={true}>
							<CMPMenuItem action={() => this.changeLanguage("el")} title={<Translate content="language.el.abbr" />} active={this.props.language === "el" ? true : false} />
							<CMPMenuItem action={() => this.changeLanguage("en")} title={<Translate content="language.en.abbr" />} active={this.props.language === "en" ? true : false} />
						</CMPMenu>
					</div>
				</div>
			</div>
		);
	}
}

export default BSNNavigationPanel;
